:root{
  --main-color: #CAB582;
  --secondary-color: #8d6e63;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-color);
  color: white;
}

a{
  color: white;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo{
  margin-top: 3vh;
  background-color: transparent;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 150px;
}