.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
  margin-bottom: 50px;
}

/* buttons */
.btn{
  appearance: none;
  -moz-appearance: none;
  border: none;
  padding: 20px 50px 20px 50px;
  margin: 20px;
  border-radius: 5px;
  box-shadow: 0px 2px 6px -5px black;
  color: white;
  background-color: var(--secondary-color);
}
.btn.consultar-cartao{
  border-radius: 0;
  width: 60%;
  margin: 0 auto;
  margin-bottom: 30px;
}
.btn.limpar{
  padding: 10px;
  background-color: white;
  color: var(--secondary-color)
}

button:hover{
  background-color: white;
  color: var(--secondary-color);
  font-weight: bold;
}
.buttons-section{
  display: flex;
  flex-direction: column;
}
.btn.danger{
  background-color: red;
}
.btn.secondary{
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}
.btn.danger:hover{
  color: white;
}
.btn.secondary.danger:hover{
  color:red;
}
.btn.dark{
  color: var(--secondary-color);
}

.notification-text{
  font-size: 25px;
  max-width: 75vw;
  white-space: pre-wrap;
}

.initial-inputs{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50%;
}

.modal{
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  color: black;
}

input, textarea, select{
  border-radius: 5px;
  border: none;
  padding: 20px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 6px -5px black;
  width: 100%;
}

/* ---------------------- */
/* SELECT */
div.form-control {
  min-width: 420px;
  max-width: 700px;
  margin: 50px 0;
}


/*  ---------------------   */
.section{
  background-color: white;
  padding: 20px;
  box-shadow: 0px 2px 6px -5px black;
  border-radius: 5px;
  margin: 10px;
  width: 80%;
}
.section-title{
  color: black;
  text-align: left;
}
.section > a{
  font-size: 20px;
  color: black;
  display: inline-block;
  margin: 10px;
}

/* Tables */
.MuiTableContainer-root{
  max-width: 70vw;
}
.MuiTableRow-root{
  border-spacing: 0 5px !important;
  border-collapse: separate !important;
}

@media (max-width: 600px){
  .MuiTableContainer-root{
    max-width: 90vw;
  }
}